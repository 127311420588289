import { trackAscEvent } from '~/composables/asc/trackAscEvent'
import type { Vehicle } from '~/utils/types/inventory'
import type { ASCPageType } from '~/utils/types/asc/enums'
import { ASCDepartment, ASCEvent } from '~/utils/types/asc/enums'
import type { ASCClickToCall, ASCVehicle } from '~/utils/types/asc'

export const trackClickToCallEvent = (params: ASCClickToCallParams) => {
    const { pageType, phoneNumber, department } = params

    const vehicle: Vehicle | null = params.vehicle ?? null
    const ascVehicle: ASCVehicle | null = vehicle
        ? useEventStore().formatVehicleForASC(vehicle)
        : null

    const event: ASCClickToCall = {
        ...{
            page_type: pageType,
            comm_phone_number: phoneNumber,
            department: department ?? ASCDepartment.UNKNOWN,
        },
        ...(ascVehicle ?? {}),
    }

    trackAscEvent({
        eventName: ASCEvent.CLICK_TO_CALL,
        event,
        pageType,
    })
}

export interface ASCClickToCallParams {
    pageType: ASCPageType
    phoneNumber: string
    department?: ASCDepartment
    vehicle?: Vehicle | null
}
