<template>
    <a :href="`tel:${phone}`" @click="sendEvent">
        <slot />
    </a>
</template>

<script setup lang="ts">
import type { PropType } from 'vue'
import { trackClickToCallEvent } from '~/composables/asc/events/ClickToCall'
import type { Vehicle } from '~/utils/types/inventory'
import { ASCDepartment, ASCPageType } from '~/utils/types/asc/enums'

const eventStore = useEventStore()

const props = defineProps({
    phone: {
        type: String,
        required: true,
    },
    department: {
        type: String as PropType<ASCDepartment | null>,
    },
    vehicle: {
        type: Object as PropType<Vehicle | null>,
    },
})

function sendEvent() {
    trackClickToCallEvent({
        pageType: eventStore.ascPageType ?? ASCPageType.UNKNOWN,
        phoneNumber: props.phone,
        department: props.department ?? ASCDepartment.UNKNOWN,
        vehicle: props.vehicle,
    })
}
</script>
